@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl font-semibold tracking-lg leading-2xl;
  }

  h2 {
    @apply text-xl font-semibold tracking-md leading-xl;
  }

  h3 {
    @apply text-lg font-semibold tracking-sm leading-lg;
  }

  p {
    @apply text-base tracking-xs leading-md;
  }

  div {
    @apply text-base tracking-xs leading-md;
  }
}

* {
  box-sizing: border-box;
  outline: none !important;
}

body,
html,
#root {
  height: 100%;
}

:root {
  --surface-base: rgba(249, 250, 251, 1);
  --surface-base-reverse: rgba(14, 18, 26, 1);
  --surface-overlay: rgba(255, 255, 255, 1);
  --surface-overlay-reverse: rgba(16, 26, 48, 1);
  --surface-alt: rgba(17, 25, 40, 0.04);
  --surface-div: rgba(229, 231, 235, 1);
  --action-hover: rgba(17, 25, 40, 0.04);
  --action-selected: rgba(17, 25, 40, 0.06);
  --action-focused: rgba(28, 100, 242, 1);
  --content-primary: rgba(17, 25, 40, 1);
  --content-secondary: rgba(107, 114, 128, 1);
  --content-tetriary: rgba(156, 163, 175, 1);
  --content-primary-reverse: rgba(243, 244, 246, 1);
  --content-secondary-reverse: rgba(156, 163, 175, 1);
  --content-tetriary-reverse: rgba(75, 85, 99, 1);
  --accent-main: rgb(242, 101, 84);
  --accent-hover: rgb(255, 132, 118);
  --accent-bg: rgba(242, 101, 84, 0.1);
  --accent-content: rgba(28, 100, 242, 0.1);
  --warning-main: rgba(227, 160, 8, 1);
  --warning-bg: rgba(250, 202, 21, 0.1);
  --warning-content: rgba(99, 49, 18, 1);
  --success-main: rgba(5, 122, 85, 1);
  --success-bg: rgba(5, 122, 85, 0.1);
  --success-content: rgba(1, 71, 55, 1);
  --error-main: rgba(224, 36, 36, 1);
  --error-hover: rgba(200, 30, 30, 1);
  --error-bg: rgba(224, 36, 36, 0.1);
  --error-content: rgba(119, 29, 29, 1);

  --rammp-purple: #53345e;
  --rammp-orange: rgb(242, 101, 84);
}

.dark {
  --surface-base: rgba(14, 18, 26, 1);
  --surface-base-reverse: rgba(249, 250, 251, 1);
  --surface-overlay: rgba(16, 26, 48, 1);
  --surface-overlay-reverse: rgba(255, 255, 255, 1);
  --surface-alt: rgba(28, 100, 242, 0.1);
  --surface-div: rgba(31, 42, 55, 1);
  --action-hover: rgba(28, 100, 242, 0.06);
  --action-selected: rgba(28, 100, 242, 0.1);
  --action-focused: rgba(63, 131, 248, 1);
  --content-primary: rgba(243, 244, 246, 1);
  --content-secondary: rgba(156, 163, 175, 1);
  --content-tetriary: rgba(75, 85, 99, 1);
  --content-primary-reverse: rgba(17, 25, 40, 1);
  --content-secondary-reverse: rgba(107, 114, 128, 1);
  --content-tetriary-reverse: rgba(156, 163, 175, 1);
  --accent-main: rgba(63, 131, 248, 1);
  --accent-hover: rgba(28, 100, 242, 1);
  --accent-bg: rgba(63, 131, 248, 0.1);
  --accent-content: rgba(63, 131, 248, 0.1);
  --warning-main: rgba(252, 233, 106, 1);
  --warning-bg: rgba(250, 202, 21, 0.1);
  --warning-content: rgba(253, 246, 178, 1);
  --success-main: rgba(49, 196, 141, 1);
  --success-bg: rgba(5, 122, 85, 0.1);
  --success-content: rgba(222, 247, 236, 1);
  --error-main: rgba(224, 36, 36, 1);
  --error-hover: rgba(200, 30, 30, 1);
  --error-bg: rgba(240, 82, 82, 0.1);
  --error-content: rgba(253, 232, 232, 1);
  --rammp-purple: #53345e;
  --rammp-orange: rgb(242, 101, 84);
}

body {
  font-family: 'Inter', sans-serif;
  color: var(--content-primary);
  background-color: var(--surface-base);
}

a {
  color: var(--accent-main);
}

a:hover {
  color: var(--accent-hover);
}

/* Fix issue with perfect scrollbar sometimes appears on the left side */
.ps__rail-y {
  left: auto !important;
  right: 0 !important;
}
